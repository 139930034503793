import React from 'react';
import { StyleSheet, View } from 'react-native';
import Modal from 'design-system/Modal';
import ModalWeb from 'design-system/ModalWeb';
import { rem } from 'design-system/values';
import Squircle from 'design-system/Squircle';
import { IconArrowBack } from 'design-system/icons/IconArrowBack';
import IconPlus from 'design-system/icons/IconPlus';
import { CardView } from 'design-system/CardView';
import Spacer from 'design-system/Spacer';
import TapableCard from 'features/accounts/components/TapableCard';
import { SettingsItem } from 'design-system/SettingsItem';
import useColors from 'hooks/useColors';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { isWeb } from '../../../../constants';
const SetRecurringModalContent = ({ onCreateSubscription, onAddToExistingSubscription, }) => {
    const colors = useColors();
    return (<>
      <SettingsItem title="Create a new recurring payment" onPress={onCreateSubscription} icon={<IconPlus color={colors.elements.brand}/>}/>
      <SettingsItem title="Add to an existing recurring payment" onPress={onAddToExistingSubscription} icon={<IconArrowBack gradientKey="darkPink"/>}/>
    </>);
};
const SetRecurringModalWebContent = ({ onCreateSubscription, onAddToExistingSubscription, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    return (<View>
      <Spacer h={16}/>
      <CardView noMargin style={styles.container}>
        <TapableCard title="Create a new recurring payment" IconComponent={() => (<Squircle color={colors.background.dark}>
              <IconPlus color={colors.elements.brand}/>
            </Squircle>)} onPress={onCreateSubscription}/>
        <TapableCard title="Add to an existing recurring payment" IconComponent={() => (<Squircle color={colors.background.dark}>
              <IconArrowBack gradientKey="darkPink"/>
            </Squircle>)} onPress={onAddToExistingSubscription}/>
      </CardView>
    </View>);
};
const SetRecurringModal = {
    show: (onCreateSubscription, onAddToExistingSubscription, onDismiss) => {
        if (isWeb) {
            ModalWeb.show(<SetRecurringModalWebContent onCreateSubscription={onCreateSubscription} onAddToExistingSubscription={onAddToExistingSubscription}/>, {
                title: 'Mark as a recurring payment',
                lightBg: true,
                titleBottomBorder: true,
                onDidHideCallback: onDismiss,
            });
        }
        else {
            Modal.show(<SetRecurringModalContent onCreateSubscription={onCreateSubscription} onAddToExistingSubscription={onAddToExistingSubscription}/>, {
                propagateSwipe: true,
                containerStyle: styles.modal,
                title: 'Mark as a recurring payment',
                textStyle: styles.title,
                onDidHideCallback: onDismiss,
            });
        }
    },
    hide: () => {
        if (isWeb) {
            ModalWeb.hide();
        }
        else {
            Modal.hide();
        }
    },
};
const styles = StyleSheet.create({
    modal: {
        paddingHorizontal: 0,
        overflow: 'hidden',
    },
    title: {
        marginBottom: rem(8),
    },
});
const styleSet = createStyleSheets((colors) => ({
    container: {
        flex: 1,
        padding: 8,
        backgroundColor: colors.background.light,
    },
}));
export default SetRecurringModal;
