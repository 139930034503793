import React, { useId } from 'react';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from '../Icon';
const IconStatusSent = ({ ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} {...props} width={16} height={17} viewBox="0 0 16 17" defaultGradientKey="skyBlue">
      <G clip-path="url(#clip0_14583_2209)">
        <Path d="M0.254777 6.46178C0.627752 3.47798 2.97798 1.12775 5.96178 0.754777C7.31533 0.585584 8.68467 0.585584 10.0382 0.754777C13.022 1.12775 15.3722 3.47798 15.7452 6.46178C15.9144 7.81533 15.9144 9.18467 15.7452 10.5382C15.3722 13.522 13.022 15.8722 10.0382 16.2452C8.68467 16.4144 7.31533 16.4144 5.96178 16.2452C2.97798 15.8722 0.627752 13.522 0.254777 10.5382C0.0855839 9.18467 0.0855839 7.81533 0.254777 6.46178Z" fill={`url(#gradient_${id})`}/>
        <Path d="M11.303 9.19818C11.2681 9.23398 11.1364 9.38707 11.0137 9.51299C10.2945 10.305 8.41829 11.6013 7.43629 11.9969C7.28716 12.0605 6.91011 12.1951 6.70866 12.2037C6.51563 12.2037 6.33162 12.1593 6.15602 12.0692C5.93713 11.9432 5.76154 11.7451 5.66532 11.5111C5.60338 11.3488 5.50717 10.863 5.50717 10.8544C5.41095 10.3229 5.35803 9.45929 5.35803 8.50497C5.35803 7.59571 5.41095 6.76732 5.48973 6.22781C5.49875 6.21917 5.59496 5.61546 5.7002 5.40867C5.89323 5.0309 6.27028 4.79633 6.67378 4.79633L6.70866 4.79633C6.97145 4.80559 7.52409 5.04016 7.52409 5.0488C8.45317 5.44509 10.2861 6.67781 11.0227 7.49695C11.0227 7.49695 11.2302 7.70744 11.3204 7.83892C11.4611 8.02781 11.5309 8.26176 11.5309 8.49571C11.5309 8.75682 11.4521 9.00003 11.303 9.19818Z" fill={colors.cards.onDark}/>
      </G>
      <Defs>
        <ClipPath id="clip0_14583_2209">
          <Rect width="16" height="16" fill={colors.cards.onDark} transform="translate(0 0.5)"/>
        </ClipPath>
      </Defs>
    </IconWithGradient>);
};
export default IconStatusSent;
