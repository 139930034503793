import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { Keyboard, View } from 'react-native';
import { emojify } from 'node-emoji';
import { AppView } from 'design-system/AppView';
import EmojiText from 'design-system/EmojiText';
import { CardView } from 'design-system/CardView';
import Button, { HEIGHT } from 'design-system/Button';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { IconEmma, IconText, IconTrash } from 'design-system/icons';
import EmojiPickerModal from 'design-system/emojiPicker/EmojiPickerModal';
import { AnimatedKeyboardAwareScrollView } from 'design-system/AnimatedComponents';
import { SettingsItemInput, SettingsItemNavigate, } from 'design-system/SettingsItem';
import withAnimatedNavigationHeader from 'design-system/hoc/withAnimatedNavigationHeader';
import NavigationHeaderRightIconButton from 'design-system/NavigationHeaderRightIconButton';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import Alert from 'utils/packages/Alert';
import createStyleSheets from 'utils/createStyleSheets';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { API_DEFAULT_CATEGORY_COLOR } from 'hooks/useCategory';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { webPickerWidth } from 'features/emoji-picker/components/EmojiSelector';
import { isWeb } from '../../../constants';
import useDeleteCategory from '../hooks/useDeleteCategory';
import WebEmojiPicker from '../components/WebEmojiPicker.web';
import CustomCategorySuggestions from '../components/categories/CustomCategorySuggestions';
import CustomCategoryTransactionsPreview from '../components/categories/CustomCategoryTransactionsPreview';
const CustomCategoryEditScreen = withAnimatedNavigationHeader(({ navigation, route, scrollHandler }) => {
    const styles = useStyles(styleSet);
    const nameInputRef = useRef(null);
    const emojiViewRef = useRef(null);
    const { paddingHorizontalStyle, sidebarWidth, width } = useAppFrameDimensions(isWeb);
    const [webEmojiPickerState, setWebEmojiPickerState] = useState(undefined);
    const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
    const { category } = route.params || {};
    const [name, setName] = useState(category?.displayName || '');
    const [emoji, setEmoji] = useState(category?.emoji);
    const [isNameFocused, setIsNameFocused] = useState(false);
    const [isFetchingDelete, deleteCategory] = useDeleteCategory();
    useLayoutEffect(() => {
        if (category) {
            navigation.setOptions({
                headerRight: () => (<NavigationHeaderRightIconButton onPress={() => {
                        deleteCategory(category.id, category.displayName, () => {
                            navigation.pop();
                        });
                    }}>
                <IconTrash colorKey="primary"/>
              </NavigationHeaderRightIconButton>),
            });
        }
    }, [category, deleteCategory]);
    const [suggestedData, setSuggestedData] = useState();
    const clearWebEmojiPickerState = useCallback(() => {
        setWebEmojiPickerState(undefined);
    }, []);
    const handleSuggestionPress = useCallback((name, emoji) => {
        Keyboard.dismiss();
        setName(name);
        setEmoji(emoji);
        clearWebEmojiPickerState();
        setSuggestedData({
            name,
            emoji,
        });
    }, [clearWebEmojiPickerState]);
    const onEmojiModalClose = useCallback(() => {
        setIsEmojiPickerOpen(false);
    }, []);
    const onSetEmoji = useCallback((emoji) => {
        setEmoji(emoji);
        setWebEmojiPickerState(undefined);
        onEmojiModalClose();
    }, [onEmojiModalClose]);
    const onPressEmoji = useCallback(() => {
        Keyboard.dismiss();
        setIsEmojiPickerOpen(true);
        if (isWeb) {
            emojiViewRef.current?.measure((_, __, ___, ____, fx, fy) => {
                setWebEmojiPickerState({
                    fx: fx - sidebarWidth + (width - webPickerWidth - rem(64)),
                    fy,
                });
            });
        }
        else {
            EmojiPickerModal.show(emoji || undefined, onSetEmoji, onEmojiModalClose);
        }
    }, [emoji, onEmojiModalClose, onSetEmoji, sidebarWidth, width]);
    const onPressSave = useCallback(() => {
        clearWebEmojiPickerState();
        if (!name.trim().length) {
            Alert.alert(emojify('Slow down :face_with_rolling_eyes:'), 'You must enter a name.');
            return;
        }
        if (!emoji) {
            Alert.alert(emojify('Slow down :face_with_rolling_eyes:'), 'You must choose an emoji.');
            return;
        }
        if (category) {
            navigation.navigate('CustomCategoryCreated', {
                category: {
                    ...category,
                    displayName: name.trim(),
                    emoji: emoji || null,
                    color: category?.color === API_DEFAULT_CATEGORY_COLOR
                        ? undefined
                        : category?.color,
                },
                keyBack: route.key,
            });
        }
        else {
            navigation.navigate('CustomCategoryCreated', {
                category: {
                    displayName: name.trim(),
                    emoji,
                },
                keyBack: route.key,
            });
        }
    }, [category, clearWebEmojiPickerState, emoji, name, route.key]);
    const colors = useColors();
    const handleEvent = useCallback((event) => () => {
        if (isWeb) {
            setIsNameFocused(event === 'focus');
        }
    }, []);
    const nameInput = (<SettingsItemInput description="Name" icon={<IconText gradientColors={colors.gradients.darkPink.colors}/>} value={name} onChangeText={setName} placeholder="Choose.." iconSize={44} ref={nameInputRef} onFocus={handleEvent('focus')} onBlur={handleEvent('blur')} returnKeyType="done" {...(isWeb && {
        style: cursorStyle,
        onPress: clearWebEmojiPickerState,
    })}/>);
    const emojiInput = (<View ref={emojiViewRef}>
          <SettingsItemNavigate title="Change" description="Emoji" icon={emoji ? (<EmojiText fontSize={20}>{emoji}</EmojiText>) : (<IconEmma />)} iconSize={44} onPress={onPressEmoji} isSelected={isEmojiPickerOpen}/>
        </View>);
    const paddingBottom = useMarginBottom('paddingBottom', HEIGHT + rem(16));
    return (<AppView type="secondary" withFrame={false}>
          <AnimatedKeyboardAwareScrollView scrollEventThrottle={16} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} onScroll={scrollHandler} contentContainerStyle={paddingBottom} keyboardShouldPersistTaps="handled">
            {category ? (<View style={paddingHorizontalStyle}>
                <CardView webPadding>
                  {nameInput}
                  {emojiInput}
                </CardView>
                <CustomCategoryTransactionsPreview category={category}/>
              </View>) : (<>
                <View style={paddingHorizontalStyle}>
                  <CardView webPadding style={[styles.card, isNameFocused && styles.activeCard]}>
                    {nameInput}
                  </CardView>
                  <CardView webPadding noMarginTop noMarginBottom style={styles.card}>
                    {emojiInput}
                  </CardView>
                </View>
                <CustomCategorySuggestions name={name} handleSuggestionPress={handleSuggestionPress} suggestedData={suggestedData}/>
              </>)}
          </AnimatedKeyboardAwareScrollView>
          <Button brand floating title={category ? 'Save' : 'Continue'} onPress={onPressSave} isFetching={isFetchingDelete}/>
          {isWeb && (<WebEmojiPicker {...webEmojiPickerState} onEmojiSelected={onSetEmoji} defaultSelectedEmoji={emoji || undefined}/>)}
        </AppView>);
}, ({ route }) => ({
    title: route.params?.category
        ? 'Edit custom category'
        : 'Create category',
}));
const cursorStyle = {
    cursor: 'text',
    margin: 0,
    paddingVertical: rem(16),
    paddingHorizontal: rem(8),
};
const styleSet = createStyleSheets((colors) => ({
    activeCard: {
        ...(isWeb && {
            borderColor: colors.elements.brand,
        }),
    },
    card: {
        ...(isWeb && {
            borderWidth: rem(1),
            borderColor: colors.cards.onDark,
        }),
    },
}));
export default CustomCategoryEditScreen;
