import { Alert } from 'react-native';
import { Tier } from 'features/premium/entities';
import { assertUnreachable } from 'utils/types';
export const getSign = (item) => {
    switch (item.type) {
        case 'DEPOSIT':
            return '+';
        case 'WITHDRAWAL':
            return '';
        default:
            if (item.amount.value > 0) {
                return '+';
            }
            return '';
    }
};
export const getPotEventName = (type) => {
    if (type) {
        switch (type) {
            case 'BASIC':
                return 'instant';
            case 'INTEREST':
                return 'easy_access';
            case 'INTEREST_NOTICE':
                return 'interest_notice';
            default:
                assertUnreachable(type);
                return '';
        }
    }
    return '';
};
export const sortPotProducts = (potProducts, potProductTierRateMap) => {
    potProducts.sort((a, b) => {
        if (a.isRecommended && !b.isRecommended)
            return -1;
        if (b.isRecommended && !a.isRecommended)
            return 1;
        if (a.type === 'BASIC')
            return 1;
        if (b.type === 'BASIC')
            return -1;
        if (a.type === 'INTEREST_NOTICE' && b.type === 'INTEREST')
            return 1;
        if (b.type === 'INTEREST_NOTICE' && a.type === 'INTEREST')
            return -1;
        const interestForProductA = Number(potProductTierRateMap[a.id][Tier.ultimate]) || 0;
        const interestForProductB = Number(potProductTierRateMap[b.id][Tier.ultimate]) || 0;
        return interestForProductB - interestForProductA;
    });
};
export const showDeletedPotAlert = () => {
    Alert.alert('Slow down 🤪', 'This pot is now closed, you can’t do much in here.');
};
export const showContinueInMobileAlert = () => {
    Alert.alert('Slow down 🤪', 'Please continue in mobile');
};
