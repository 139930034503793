import React, { memo, useMemo } from 'react';
import { View } from 'react-native';
import { IconGoal, IconText, IconBank, IconTrash, IconWallet, IconMoneyBill, IconBoldGallery, } from 'design-system/icons';
import IconBlock from 'design-system/icons/IconBlock';
import PopUpMenuModal from 'design-system/PopUpMenuModal.web';
import { IconOpenSquircle, rem, SettingsItem } from 'design-system';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import Modal from 'utils/packages/Modal';
import { assertUnreachable } from 'utils';
import { muteAll, useFetchOld } from 'hooks';
import { deleteGoal, getAccountGoals } from 'actions';
import createStyleSheets from 'utils/createStyleSheets';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import IconCategoryExcluded from 'features/transactions/icons/categories/IconCategoryExcluded';
import { isWeb } from '../../../../constants';
import AccountTypeMenu from './AccountTypeMenu';
import { changeableAccountTypes } from './accountType';
import { useHideAccount } from '../../hooks/useHideAccount';
import { useCloseAccount } from '../../hooks/useCloseAccount';
import { useDeleteAccount } from '../../hooks/useDeleteAccount';
var MenuItemType;
(function (MenuItemType) {
    MenuItemType["Saving"] = "SAVING";
    MenuItemType["SavingEdit"] = "SAVING_EDIT";
    MenuItemType["SavingDelete"] = "DEL_SAVING";
    MenuItemType["Hide"] = "HIDE";
    MenuItemType["Close"] = "CLOSED";
    MenuItemType["Rename"] = "RENAME";
    MenuItemType["ChangeType"] = "TYPE";
    MenuItemType["Overdraft"] = "OVERDRAFT";
    MenuItemType["CloseManual"] = "MANUAL_CLOSE";
    MenuItemType["RenameManual"] = "MANUAL_RENAME";
    MenuItemType["UpdateLogo"] = "UPDATE_LOGO";
    MenuItemType["ChangeBalance"] = "MANUAL_BALANCE";
    MenuItemType["DeleteManual"] = "MANUAL_DELETE";
})(MenuItemType || (MenuItemType = {}));
const ModalToUse = isWeb ? PopUpMenuModal : Modal;
const unavailableDueToLinkedToAutosave = `This account is linked to Emma Autosave, so this action isn't available.`;
const AccountMenu = ({ account, navigation, navigateBackAfterDelete = true, }) => {
    const styles = useStyles(styleSet);
    const goals = useAppSelector((store) => store.accounts.goals);
    const savingsPlanAccountId = useAppSelector((state) => state.pots.savingsPlan?.bankInfo?.accountId);
    const colors = useColors();
    const autosaveAddition = useMemo(() => {
        const isAccountLinkedToAutosave = savingsPlanAccountId === account.id;
        return isAccountLinkedToAutosave
            ? {
                disabled: true,
                style: styles.disabled,
                description: unavailableDueToLinkedToAutosave,
            }
            : {};
    }, [account.id, savingsPlanAccountId, styles.disabled]);
    const config = useMemo(() => [
        {
            id: MenuItemType.CloseManual,
            text: 'Mark as closed',
            icon: <IconBlock />,
            notOnTypes: ['REAL_ESTATE', 'VEHICLE', 'OTHER'],
            manual: true,
            closed: false,
            sortingOrder: 10,
        },
        {
            id: MenuItemType.CloseManual,
            text: 'Mark as open',
            icon: <IconOpenSquircle />,
            notOnTypes: ['REAL_ESTATE', 'VEHICLE', 'OTHER'],
            manual: true,
            closed: true,
            sortingOrder: 10,
        },
        {
            id: MenuItemType.Saving,
            text: 'Set a saving goal',
            icon: <IconGoal />,
            closed: false,
            manual: true,
            types: ['SAVINGS'],
            goals: false,
            sortingOrder: 0,
        },
        {
            id: MenuItemType.SavingEdit,
            text: 'Edit saving goal',
            icon: <IconGoal />,
            closed: false,
            manual: true,
            types: ['SAVINGS'],
            goals: true,
            sortingOrder: 0,
        },
        {
            id: MenuItemType.SavingDelete,
            text: 'Delete saving goal',
            icon: <IconTrash />,
            closed: false,
            manual: true,
            types: ['SAVINGS'],
            goals: true,
            sortingOrder: 1,
            ...autosaveAddition,
        },
        {
            id: MenuItemType.RenameManual,
            text: 'Rename',
            icon: <IconText />,
            closed: false,
            manual: true,
            sortingOrder: 2,
        },
        {
            id: MenuItemType.UpdateLogo,
            text: 'Update logo',
            icon: <IconBoldGallery />,
            closed: false,
            manual: true,
            sortingOrder: 3,
        },
        {
            id: MenuItemType.ChangeBalance,
            text: 'Change balance',
            icon: <IconWallet gradientKey="lightPink"/>,
            closed: false,
            manual: true,
            sortingOrder: 4,
        },
        {
            id: MenuItemType.DeleteManual,
            text: 'Delete this account',
            icon: <IconTrash gradientKey="apricot"/>,
            closed: false,
            manual: true,
            sortingOrder: 9,
        },
        {
            id: MenuItemType.Close,
            text: 'Mark as closed',
            icon: <IconBlock />,
            closed: false,
            manual: false,
            sortingOrder: 10,
            ...autosaveAddition,
        },
        {
            id: MenuItemType.Close,
            text: 'Mark as open',
            icon: <IconOpenSquircle />,
            closed: true,
            manual: false,
            sortingOrder: 10,
        },
        {
            id: MenuItemType.Saving,
            text: 'Set a saving goal',
            icon: <IconGoal />,
            closed: false,
            manual: false,
            types: ['SAVINGS'],
            goals: false,
            sortingOrder: 0,
        },
        {
            id: MenuItemType.SavingEdit,
            text: 'Edit saving goal',
            icon: <IconGoal />,
            closed: false,
            manual: false,
            types: ['SAVINGS'],
            goals: true,
            sortingOrder: 0,
        },
        {
            id: MenuItemType.SavingDelete,
            text: 'Delete saving goal',
            icon: <IconTrash />,
            closed: false,
            manual: false,
            types: ['SAVINGS'],
            goals: true,
            sortingOrder: 1,
        },
        {
            id: MenuItemType.Hide,
            text: 'Hide',
            icon: (<IconCategoryExcluded width={rem(24)} height={rem(24)} viewBox="0 0 24 24" fill={colors.status.offline}/>),
            closed: false,
            manual: false,
            hidden: false,
            sortingOrder: 2,
        },
        {
            id: MenuItemType.Hide,
            text: 'Unhide',
            icon: (<IconCategoryExcluded width={rem(24)} height={rem(24)} viewBox="0 0 24 24" fill={colors.status.offline}/>),
            closed: false,
            manual: false,
            hidden: true,
            sortingOrder: 2,
        },
        {
            id: MenuItemType.Rename,
            text: 'Rename',
            icon: <IconText />,
            closed: false,
            manual: false,
            sortingOrder: 3,
        },
        {
            id: MenuItemType.ChangeType,
            text: 'Change type',
            icon: <IconBank gradientKey="lightPurple"/>,
            closed: false,
            sortingOrder: 4,
            types: changeableAccountTypes,
            ...autosaveAddition,
        },
        {
            id: MenuItemType.Overdraft,
            text: 'Set overdraft',
            icon: <IconMoneyBill gradientKey="skyBlue"/>,
            closed: false,
            manual: false,
            sortingOrder: 5,
            types: ['CHECKING'],
        },
    ], [autosaveAddition, colors.status.offline]);
    const options = useMemo(() => {
        const { isClosed, isHidden, provider, proPreviewAccount, ultimatePreviewAccount, } = account;
        const { type } = account;
        const isGoal = Boolean(goals[account.id]);
        const isManual = provider === 'MANUAL';
        const filteredItems = config.filter((menuItem) => {
            if (proPreviewAccount && isClosed && isManual && menuItem.manual) {
                // For downgraded manual accounts we only allow deletion not reopening
                if (menuItem.id === MenuItemType.CloseManual) {
                    return false;
                }
                if (menuItem.id === MenuItemType.DeleteManual) {
                    return true;
                }
            }
            if (ultimatePreviewAccount) {
                return menuItem.hidden === isHidden;
            }
            if (menuItem.manual !== undefined) {
                if (menuItem.manual !== isManual) {
                    return false;
                }
            }
            if (menuItem.closed !== undefined) {
                if (menuItem.closed !== isClosed) {
                    return false;
                }
            }
            if (menuItem.goals !== undefined) {
                if (menuItem.goals !== isGoal) {
                    return false;
                }
            }
            if (menuItem.hidden !== undefined) {
                if (menuItem.hidden !== isHidden) {
                    return false;
                }
            }
            if (menuItem.notOnTypes) {
                if (menuItem.notOnTypes.findIndex((t) => t === type) !== -1) {
                    return false;
                }
            }
            if (menuItem.types) {
                if (menuItem.types.findIndex((t) => t === type) === -1) {
                    return false;
                }
            }
            return true;
        });
        filteredItems.sort((a, b) => a.sortingOrder - b.sortingOrder);
        return filteredItems;
    }, [account, config, goals]);
    const dispatch = useAppDispatch();
    const [, , , fetch] = useFetchOld(muteAll);
    const closeAccount = useCloseAccount();
    const hideAccount = useHideAccount();
    const deleteAccount = useDeleteAccount();
    const onOptionPressed = (data) => {
        if (!data)
            return;
        switch (data) {
            case MenuItemType.Saving:
                ModalToUse.hide(() => {
                    navigation.navigate('SavingGoalEdit', {
                        accountId: account.id,
                    });
                });
                break;
            case MenuItemType.SavingEdit:
                ModalToUse.hide(() => {
                    navigation.navigate('SavingGoalEdit', {
                        accountId: account.id,
                        goalId: goals?.[account.id]?.id,
                    });
                });
                break;
            case MenuItemType.SavingDelete:
                ModalToUse.hide();
                if (goals && goals[account.id]) {
                    fetch(deleteGoal(goals[account.id].id, account.id)).then((resp) => {
                        if (resp) {
                            dispatch(getAccountGoals());
                        }
                    });
                }
                break;
            case MenuItemType.Rename:
                ModalToUse.hide(() => {
                    navigation.navigate('EditAccountName', { id: account.id });
                });
                break;
            case MenuItemType.Hide: {
                const hideUnhideFn = () => {
                    hideAccount(account);
                    ModalToUse.hide(() => {
                        if (navigateBackAfterDelete) {
                            navigation.goBack();
                        }
                    });
                };
                if (account.isHidden) {
                    hideUnhideFn();
                }
                else {
                    ModalToUse.hide(() => {
                        Modal.showConfirmation('Are you sure?', 'This account will be moved to the Hidden section at the bottom of this list and the data won’t be visible across the app. If you want it back, just unhide it from the Hidden section.', 'Yes, let me hide', 'No, thanks', hideUnhideFn);
                    });
                }
                break;
            }
            case MenuItemType.ChangeType:
                if (account) {
                    ModalToUse.hide(() => {
                        Modal.show(<AccountTypeMenu accountId={account.id} currentAccountType={account.type}/>, {
                            title: 'What account is this?',
                            containerStyle: styles.modalContentContainer,
                            lightBg: true,
                        });
                    });
                }
                break;
            case MenuItemType.Overdraft:
                ModalToUse.hide(() => {
                    navigation.navigate('EditOverdraft', { id: account.id });
                });
                break;
            case MenuItemType.Close:
            case MenuItemType.CloseManual: {
                const closeOpenFn = () => {
                    closeAccount(account);
                    ModalToUse.hide(() => {
                        if (navigateBackAfterDelete) {
                            navigation.goBack();
                        }
                    });
                };
                if (account.isClosed) {
                    closeOpenFn();
                }
                else {
                    ModalToUse.hide(() => {
                        Modal.showConfirmation('Are you sure?', 'This account will be moved to the Closed section at the bottom of this list and historical data will still be visible across the app. If you want it back, just reopen it from the Closed section.', 'Yes, let me close', 'No, thanks', closeOpenFn);
                    });
                }
                break;
            }
            case MenuItemType.UpdateLogo:
                ModalToUse.hide(() => {
                    navigation.navigate('DummyAccountLogoType', {
                        id: account.id,
                        isChangingLogo: true,
                    });
                });
                break;
            case MenuItemType.RenameManual:
                ModalToUse.hide(() => {
                    navigation.navigate('DummyAccountSetup', {
                        id: account.id,
                        isChangingName: true,
                    });
                });
                break;
            case MenuItemType.ChangeBalance:
                ModalToUse.hide(() => {
                    navigation.navigate('AddBalance', {
                        id: account.id,
                        isChangingBalance: true,
                    });
                });
                break;
            case MenuItemType.DeleteManual:
                ModalToUse.hide(() => {
                    Modal.showConfirmation('Delete account', 'Would you like to delete this account?', 'Yes, delete', 'No, keep it', () => {
                        deleteAccount(account);
                        if (navigateBackAfterDelete) {
                            navigation.goBack();
                        }
                    });
                });
                break;
            default:
                assertUnreachable(data);
                break;
        }
    };
    const content = options.map((item) => (<SettingsItem key={item.id} icon={item.icon} title={item.text} style={item.style} disabled={!!item.disabled} description={item.description} onPress={() => onOptionPressed(item.id)}/>));
    if (isWeb) {
        return <View style={styles.accountMenu}>{content}</View>;
    }
    return <View>{content}</View>;
};
const styleSet = createStyleSheets((colors) => ({
    disabled: {
        opacity: 0.5,
    },
    modalContentContainer: {
        paddingHorizontal: isWeb ? undefined : 0,
    },
    accountMenu: {
        ...(isWeb && {
            padding: rem(8),
            backgroundColor: colors.cards.onDark,
        }),
    },
}));
export default memo(AccountMenu);
