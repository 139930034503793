import React, { useCallback, useState, useRef } from 'react';
import { Platform, StyleSheet, View, Keyboard } from 'react-native';
import Alert from 'utils/packages/Alert';
import { rem, SCROLL_INDICATOR_INSETS } from 'design-system/values';
import { CardView } from 'design-system/CardView';
import { AppView } from 'design-system/AppView';
import { SettingsItemInput } from 'design-system/SettingsItem';
import { AnimatedKeyboardAwareScrollView } from 'design-system/AnimatedComponents';
import { HEIGHT } from 'design-system/Button';
import { IconText } from 'design-system/icons';
import KeyboardAvoidingView from 'design-system/KeyboardAvoidingView';
import { useAppDispatch } from 'store/hooks';
import { useFetch } from 'hooks';
import useColors from 'hooks/useColors';
import useAppFrameDimensions from 'hooks/useAppFrameDimensions';
import { useMarginBottom } from 'hooks/useMarginBottom';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import { fetchSpacesAction, setSpaceName } from '../actions';
import SpaceNameSuggestions from '../components/SpaceNameSuggestions';
import { CreateSpacePageButton } from '../components';
import { isWeb } from '../../../constants';
const showValidationAlert = () => Alert.alert("Sorry, can't use that 😭", 'Enter 3 symbols minimum.', [
    {
        text: 'Ok, thanks',
    },
]);
const SpaceNamePage = ({ space, buttonTitle, onSubmitted, }) => {
    const dispatch = useAppDispatch();
    const [text, setText] = useState(space?.name || '');
    const [setName, busy] = useFetch();
    const [isFocused, setIsFocused] = useState(false);
    const handleFocus = useCallback(() => {
        setIsFocused(true);
    }, []);
    const handleBlur = useCallback(() => {
        setIsFocused(false);
    }, []);
    const confirm = useCallback((data) => {
        const name = data ?? text;
        if (name.length < 3) {
            showValidationAlert();
        }
        else if (space?.id) {
            setName(setSpaceName(space.id, name))
                .then(() => {
                dispatch(fetchSpacesAction());
                onSubmitted?.(space);
            })
                .catch(() => { });
        }
        else {
            onSubmitted?.({
                id: -1,
                name,
                type: 'PERSONAL',
                accessCode: '',
                accessLink: '',
                createdBy: -1,
                createdAt: '',
                isDefault: false,
            });
        }
    }, [text, space, setName, onSubmitted]);
    const [suggestedData, setSuggestedData] = useState(undefined);
    const handleSuggestionPress = useCallback((text) => {
        Keyboard.dismiss();
        setText(text);
        setSuggestedData({
            name: text,
        });
    }, []);
    const colors = useColors();
    const nameInputRef = useRef(null);
    useAutoFocusInput(nameInputRef);
    const nameInput = (<SettingsItemInput ref={nameInputRef} description="Name" icon={<IconText gradientColors={colors.gradients.darkPink.colors}/>} value={text} onChangeText={setText} placeholder="Enter name" iconSize={44} returnKeyType="done" onFocus={handleFocus} onBlur={handleBlur}/>);
    const { paddingHorizontalStyle } = useAppFrameDimensions(isWeb);
    const paddingBottom = useMarginBottom('paddingBottom', HEIGHT + rem(16));
    const content = (<View style={styles.content}>
      <AnimatedKeyboardAwareScrollView scrollEventThrottle={16} scrollIndicatorInsets={SCROLL_INDICATOR_INSETS} contentContainerStyle={paddingBottom} keyboardShouldPersistTaps="handled">
        {space ? (<View style={paddingHorizontalStyle}>
            <CardView selectable={isWeb} webPadding isSelected={isFocused}>
              {nameInput}
            </CardView>
          </View>) : (<>
            <View style={paddingHorizontalStyle}>
              <CardView selectable={isWeb} isSelected={isFocused} noMarginBottom>
                {nameInput}
              </CardView>
            </View>
            <SpaceNameSuggestions name={text} handleSuggestionPress={handleSuggestionPress} suggestedData={suggestedData}/>
          </>)}
      </AnimatedKeyboardAwareScrollView>
      <CreateSpacePageButton buttonTitle={buttonTitle} onClick={confirm} buttonVisible buttonPending={busy}/>
    </View>);
    return (<AppView avoidHeader withFrame={false}>
      {Platform.OS === 'ios' ? (<KeyboardAvoidingView behavior="padding" style={styles.container}>
          {content}
        </KeyboardAvoidingView>) : (content)}
    </AppView>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
    },
    content: {
        flex: 1,
        overflow: 'hidden',
    },
});
export default SpaceNamePage;
