import { LOGGED_OUT } from 'actions/types';
import { GET_RECENT_INTERACTIONS_SUCCESS } from '../actions/types';
const initialState = {
    recentInteractions: [],
};
// move away from deprecated createFetchReducer
// move and fix necessary payments related reducers into here
const paymentsV2 = (state = initialState, action) => {
    switch (action.type) {
        case GET_RECENT_INTERACTIONS_SUCCESS: {
            return {
                ...state,
                recentInteractions: action.payload.suggestedContacts.map((c) => 'beneficiaryId' in c ? { ...c, id: c.beneficiaryId } : c),
            };
        }
        case LOGGED_OUT: {
            return initialState;
        }
        default:
            return state;
    }
};
export default paymentsV2;
