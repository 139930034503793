import React, { useCallback, useMemo, useState } from 'react';
import { View, FlatList, Platform, useWindowDimensions, StyleSheet, } from 'react-native';
import emoji from 'emoji-datasource';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import Modal from 'design-system/Modal';
import Button from 'design-system/Button';
import { rem } from 'design-system/values';
import { Spacer } from 'design-system/Spacer';
import SearchBar from 'design-system/SearchBar';
import useStyles from 'hooks/useStyles';
import EmojiSelector, { charFromEmojiObject, } from 'features/emoji-picker/components/EmojiSelector';
import { useKeyboard } from 'hooks/useKeyBoard';
import createStyleSheets from 'utils/createStyleSheets';
import useColors from 'hooks/useColors';
import EmojiItem from './EmojiItem';
import { isWeb } from '../../constants';
const keyExtractor = (emoji) => emoji.unified;
export const EmojiPickerModalContent = ({ onEmojiSelected, defaultSelectedEmoji, }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const onChangeSearchTerm = useCallback((searchTerm) => {
        setSearchTerm(searchTerm);
    }, []);
    const keyboardHeight = useKeyboard(true);
    const searchEmojis = useMemo(() => searchTerm.trim().length
        ? emoji.filter((emoji) => emoji.short_name.includes(searchTerm.toLowerCase()))
        : [], [searchTerm]);
    const { bottom } = useSafeAreaInsets();
    const isKeyboardOpen = Boolean(keyboardHeight);
    const [selectedEmoji, setSelectedEmoji] = useState(defaultSelectedEmoji || '');
    const onPressSave = useCallback(() => {
        Modal.hide();
        onEmojiSelected(selectedEmoji);
    }, [onEmojiSelected, selectedEmoji]);
    const styles = useStyles(styleSet);
    const renderSearchItem = useCallback(({ item: e, index }) => (<>
        <EmojiItem item={e} key={e.unified} onSelected={setSelectedEmoji} isSelected={charFromEmojiObject(e) === selectedEmoji}/>
        {index < searchEmojis.length - 1 && <Spacer w={16}/>}
      </>), [searchEmojis.length, selectedEmoji]);
    const colors = useColors();
    const { width, height } = useWindowDimensions();
    const isLandscape = width > height;
    return (<>
      <SearchBar isInNativeModal={isWeb} onChangeSearchTerm={onChangeSearchTerm} textFieldBackgroundColor={isWeb ? colors.cards.onLight : colors.background.dark}/>
      {!searchTerm.length && !keyboardHeight && (<EmojiSelector selected={selectedEmoji} onSelected={setSelectedEmoji} categoryListStyle={styles.categoryContainer}/>)}
      {Boolean(!isKeyboardOpen || searchEmojis.length) && (<FlatList data={searchEmojis} renderItem={renderSearchItem} contentContainerStyle={styles.searchContent} horizontal showsHorizontalScrollIndicator={false} keyboardShouldPersistTaps="handled" keyExtractor={keyExtractor}/>)}
      {Boolean(!isKeyboardOpen || searchTerm.length) && (<Button brand title="Save" onPress={onPressSave} style={[
                styles.button,
                isKeyboardOpen && styles.buttonWithMarginBottom,
            ]} disabled={!selectedEmoji}/>)}
      {isKeyboardOpen &&
            Platform.OS === 'ios' &&
            (!Platform.isPad || isLandscape) && (<View style={{ height: keyboardHeight - bottom }}/>)}
    </>);
};
const styleSet = createStyleSheets((colors) => ({
    searchContent: {
        paddingHorizontal: rem(16),
        marginBottom: rem(24),
    },
    button: {
        marginHorizontal: rem(16),
    },
    buttonWithMarginBottom: {
        marginBottom: rem(16),
    },
    categoryContainer: {
        marginBottom: 0,
        marginTop: 0,
        ...(isWeb
            ? {}
            : {
                backgroundColor: colors.background.dark,
            }),
        shadowColor: undefined,
        shadowRadius: 0,
        shadowOpacity: 0,
        elevation: 0,
    },
}));
const EmojiPickerModal = {
    show: (emoji, onSelectEmoji, onCloseModal) => {
        Modal.show(<EmojiPickerModalContent defaultSelectedEmoji={emoji} onEmojiSelected={onSelectEmoji}/>, {
            title: 'Choose Emoji',
            propagateSwipe: true,
            onBackdropPress: onCloseModal,
            onSwipeComplete: onCloseModal,
            containerStyle: styles.containerStyle,
        });
    },
    hide: () => {
        Modal.hide();
    },
};
const styles = StyleSheet.create({
    containerStyle: {
        paddingHorizontal: 0,
    },
});
export default EmojiPickerModal;
