import React, { useId } from 'react';
import { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import useColors from 'hooks/useColors';
import { IconWithGradient } from '../Icon';
const IconStatusReceived = ({ ...props }) => {
    const colors = useColors();
    const id = useId();
    return (<IconWithGradient id={id} {...props} width={16} height={17} viewBox="0 0 16 17" defaultGradientKey="ultimate">
      <G clip-path="url(#clip0_14583_2222)">
        <Path d="M0.254777 6.46178C0.627752 3.47798 2.97798 1.12775 5.96178 0.754777C7.31533 0.585584 8.68467 0.585584 10.0382 0.754777C13.022 1.12775 15.3722 3.47798 15.7452 6.46178C15.9144 7.81533 15.9144 9.18467 15.7452 10.5382C15.3722 13.522 13.022 15.8722 10.0382 16.2452C8.68467 16.4144 7.31533 16.4144 5.96178 16.2452C2.97798 15.8722 0.627752 13.522 0.254777 10.5382C0.0855839 9.18467 0.0855839 7.81533 0.254777 6.46178Z" fill={`url(#gradient_${id})`}/>
        <Path d="M4.69692 7.80182C4.7318 7.76602 4.86349 7.61293 4.98617 7.48701C5.70538 6.69503 7.58158 5.39873 8.56359 5.00305C8.71272 4.93947 9.08977 4.80491 9.29122 4.79626C9.48425 4.79626 9.66826 4.84071 9.84386 4.93083C10.0627 5.05676 10.2383 5.25491 10.3346 5.48886C10.3965 5.6512 10.4927 6.13701 10.4927 6.14565C10.5889 6.67713 10.6418 7.54071 10.6418 8.49503C10.6418 9.40429 10.5889 10.2327 10.5102 10.7722C10.5011 10.7808 10.4049 11.3845 10.2997 11.5913C10.1066 11.9691 9.7296 12.2037 9.3261 12.2037L9.29122 12.2037C9.02843 12.1944 8.47579 11.9598 8.47579 11.9512C7.54671 11.5549 5.7138 10.3222 4.97715 9.50305C4.97715 9.50305 4.76968 9.29256 4.67948 9.16108C4.53876 8.97219 4.46901 8.73824 4.46901 8.50429C4.46901 8.24318 4.54778 7.99997 4.69692 7.80182Z" fill={colors.cards.onDark}/>
      </G>
      <Defs>
        <ClipPath id="clip0_14583_2222">
          <Rect width="16" height="16" fill={colors.cards.onDark} transform="translate(0 0.5)"/>
        </ClipPath>
      </Defs>
    </IconWithGradient>);
};
export default IconStatusReceived;
