import { useCallback, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { selectMerchants } from 'reducers/selectors';
import { useAppSelector } from 'store/hooks';
import { selectBudgetedMerchants } from '../selectors';
export const useOnSearch = (onlyWithoutBudget) => {
    const [searchFilter, setSearchFilter] = useState('');
    const merchants = useAppSelector(selectMerchants);
    const budgetedMerchants = useAppSelector(selectBudgetedMerchants);
    const wordsToMatch = searchFilter.toLowerCase().split(' ');
    const filteredMerchants = useMemo(() => {
        const budgetFilteredMerchants = onlyWithoutBudget
            ? merchants.filter((merchant) => !budgetedMerchants.some((budgeted) => budgeted.id === merchant.id))
            : merchants;
        if (!searchFilter) {
            return budgetFilteredMerchants;
        }
        return budgetFilteredMerchants.filter((merchant) => wordsToMatch.every((word) => merchant &&
            merchant.displayName &&
            merchant.displayName.toLowerCase().match(word)));
    }, [
        merchants,
        budgetedMerchants,
        wordsToMatch,
        searchFilter,
        onlyWithoutBudget,
    ]);
    const debouncedSetPosition = useRef(debounce((text) => {
        setSearchFilter(text.replace(/[`~!#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, ' '));
    }, 250, { leading: true })).current;
    const handleSearch = useCallback((text) => {
        debouncedSetPosition(text);
    }, [debouncedSetPosition]);
    const handleReset = useCallback(() => {
        setSearchFilter('');
    }, []);
    return { searchFilter, handleSearch, handleReset, filteredMerchants };
};
