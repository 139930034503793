import getSymbolFromCurrency from 'currency-symbol-map';
import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { View } from 'react-native';
import { rem, Spacer } from 'design-system';
import IconCircle from 'design-system/icons/IconCircle';
import Text from 'design-system/Text';
import BlurAmountView from 'design-system/BlurAmountView';
import IconTransactionCategory from 'features/transactions/icons/IconTransactionCategory';
import { selectIsScrambled } from 'hooks';
import useColors from 'hooks/useColors';
import useStyles from 'hooks/useStyles';
import { useAppSelector } from 'store/hooks';
import { useAutoFocusInput } from 'hooks/useAutoFocusInput';
import createStyleSheets from 'utils/createStyleSheets';
import { formatAmount } from 'utils/formatting';
import TextInputMask from 'utils/packages/rnMaskedText';
import TouchableHighlight from 'utils/packages/TouchableHighlight';
import Logo from 'design-system/Logo';
import { isWeb } from '../../../constants';
const BudgetCategoryEditRow = ({ budget, onChange, hasAverages, withInitialFocus, }) => {
    const colors = useColors();
    const styles = useStyles(styleSet);
    const value = useMemo(() => (budget.merchant ? budget.budgetLimit : budget.totalLimit) || 0, 
    // @ts-ignore
    [budget?.budgetLimit, budget.merchant, budget.totalLimit]);
    const textInputMaskRef = useRef();
    const scrambled = useAppSelector(selectIsScrambled);
    useAutoFocusInput(textInputMaskRef);
    useEffect(() => {
        if (withInitialFocus) {
            const timeout = setTimeout(() => {
                textInputMaskRef.current?.getElement()?.focus();
            }, 350);
            return () => {
                clearTimeout(timeout);
            };
        }
        return () => { };
    }, [withInitialFocus]);
    const onChangeText = useCallback((value, rawValue) => {
        if (rawValue !== undefined) {
            onChange(budget.key || '', rawValue);
        }
    }, [budget.key, onChange]);
    const textInputOptions = useMemo(() => ({
        unit: getSymbolFromCurrency(budget.currency),
        separator: '.',
        delimiter: ',',
        precision: 2,
    }), [budget.currency]);
    const renderAmountRow = useMemo(() => {
        if (budget.shouldRollover && budget.rollingAccumulatedLimit) {
            return (<BlurAmountView enabled={scrambled}>
          <Text TextThin-14 Gray>
            Rolling amount{' '}
            {formatAmount(budget.rollingAccumulatedLimit, budget.currency, 2)}
          </Text>
        </BlurAmountView>);
        }
        if (hasAverages) {
            return (<BlurAmountView enabled={scrambled}>
          <Text TextThin-14 Gray>
            3-months avg{' '}
            {formatAmount(budget.previousAverage, budget.currency, 2)}
          </Text>
        </BlurAmountView>);
        }
        return null;
    }, [
        budget.currency,
        budget.previousAverage,
        budget.rollingAccumulatedLimit,
        budget.shouldRollover,
        hasAverages,
        scrambled,
    ]);
    const handlePress = useCallback(() => {
        textInputMaskRef.current?.getElement()?.focus();
    }, []);
    const txnCategory = useMemo(() => {
        const id = budget.key?.split('.')[1];
        return {
            id: id,
            emoji: budget.emoji ?? undefined,
            color: budget.color ?? undefined,
        };
    }, [budget.color, budget.emoji, budget.key]);
    return (<TouchableHighlight onCard hoverStyle={{}} activeOpacity={1} onPress={handlePress} underlayColor={colors.background.underlay}>
      <View style={styles.container}>
        <IconCircle width={44} height={44}>
          {budget.merchant && budget.iconUrl ? (<Logo R44 uri={budget.iconUrl} resizeMode="contain"/>) : (<IconTransactionCategory category={txnCategory}/>)}
        </IconCircle>
        <View style={styles.content}>
          <View style={styles.flex}>
            <Text Text-16>{budget.displayName}</Text>
            <Spacer h={rem(4)}/>
            {renderAmountRow}
          </View>
          <TextInputMask type="money" maxLength={12} returnKeyType="done" value={value} ref={textInputMaskRef} keyboardType="number-pad" options={textInputOptions} includeRawValueInChangeText onChangeText={onChangeText} underlineColorAndroid="transparent" selectionColor={colors.text.brand} style={styles.textInput}/>
        </View>
      </View>
    </TouchableHighlight>);
};
const styleSet = createStyleSheets((colors) => ({
    flex: {
        flex: 1,
    },
    container: {
        padding: rem(16),
        flexDirection: 'row',
        alignItems: 'center',
        ...(isWeb && {
            paddingHorizontal: rem(8),
        }),
    },
    content: {
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginStart: rem(16),
    },
    textContainer: {
        flex: 1,
        marginRight: rem(8),
    },
    textInput: {
        fontFamily: 'Nunito-Bold',
        fontSize: rem(20),
        color: colors.text.primary,
        paddingLeft: rem(16),
        paddingVertical: rem(8),
        ...(isWeb && {
            width: rem(150),
            textAlign: 'right',
            backgroundColor: colors.background.dark,
        }),
    },
}));
export default memo(BudgetCategoryEditRow);
