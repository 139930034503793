import React from 'react';
import { getDay, parseISO } from 'date-fns';
import Text from 'design-system/Text';
import { StyleSheet, TouchableWithoutFeedback, View } from 'react-native';
import { rem } from 'design-system/values';
import { AccentContainer } from 'design-system/AccentContainer';
import createStyleSheets from 'utils/createStyleSheets';
import useStyles from 'hooks/useStyles';
import { isWeb } from '../../constants';
const EdgeDayText = ({ isToday, children, ...props }) => isToday ? (<Text Numbers-16 White {...props}>
      {children}
    </Text>) : (<Text Numbers-14 White>
      {children}
    </Text>);
const DayText = ({ isToday, children, ...props }) => isToday ? (<Text Numbers-16 Gray {...props}>
      {children}
    </Text>) : (<Text Numbers-14 Gray>
      {children}
    </Text>);
export const Day = React.memo(({ isInRange, date, onDayPress, isToday, isEndDay, isStartDay, isDisabled, }) => {
    const styles = useStyles(styleSet);
    const isEdgeDay = isStartDay || isEndDay;
    const isMonday = getDay(parseISO(date.dateString)) === 1;
    const isSunday = getDay(parseISO(date.dateString)) === 0;
    const inRangeStyle = [
        styles.inRangeMask,
        isWeb && isSunday && !isEndDay && styles.inRangeMaskSunday,
        isStartDay && styles.startDayMask,
        isEndDay && styles.endDayMask,
        isWeb && isEndDay && isMonday && styles.inRangeMaskMondayEnd,
        isInRange && !isStartDay && isMonday && styles.leftBorderRadius,
        isInRange && !isEndDay && isSunday && styles.rightBorderRadius,
    ].filter(Boolean);
    return (<TouchableWithoutFeedback onPress={() => {
            if (!isDisabled && onDayPress) {
                onDayPress(date);
            }
        }}>
        <View style={styles.flexContainer}>
          <View style={[
            styles.container,
            isDisabled && !isInRange && styles.disabled,
        ]}>
            {isInRange && <View style={inRangeStyle}/>}
            <AccentContainer style={[styles.dayContainer, isEdgeDay && styles.edgeRangeDay]}>
              {isEdgeDay ? (<EdgeDayText isToday={isToday}>{date?.day}</EdgeDayText>) : (<DayText isToday={isToday}>{date?.day}</DayText>)}
            </AccentContainer>
          </View>
        </View>
      </TouchableWithoutFeedback>);
});
const styleSet = createStyleSheets((colors) => {
    const dayCircleSize = rem(38.5);
    return {
        flexContainer: {
            flexDirection: 'row',
            ...(isWeb && {
                cursor: 'pointer',
            }),
        },
        container: {
            flex: 1,
            minHeight: dayCircleSize,
            alignItems: 'center',
        },
        edgeRangeDay: {
            backgroundColor: colors.elements.brand,
            borderRadius: rem(50),
        },
        dayContainer: {
            borderRadius: 50,
            width: dayCircleSize,
            height: dayCircleSize,
            justifyContent: 'center',
            alignItems: 'center',
            ...(isWeb && { backgroundColor: colors.cards.onLight }),
        },
        inRangeMask: {
            ...StyleSheet.absoluteFillObject,
            backgroundColor: colors.background.dark,
            ...(isWeb && { width: '230%', backgroundColor: colors.cards.onLight }),
        },
        inRangeMaskSunday: {
            width: '100%',
        },
        startDayMask: {
            left: dayCircleSize / 2,
            ...(isWeb && {
                left: 0,
                borderTopLeftRadius: 50,
                borderBottomLeftRadius: 50,
            }),
        },
        endDayMask: {
            right: dayCircleSize / 2,
            ...(isWeb && {
                width: 0,
            }),
        },
        leftBorderRadius: {
            borderTopLeftRadius: rem(8),
            borderBottomLeftRadius: rem(8),
        },
        rightBorderRadius: {
            borderTopRightRadius: rem(8),
            borderBottomRightRadius: rem(8),
        },
        inRangeMaskMondayEnd: {
            width: '100%',
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
        },
        disabled: {
            opacity: 0.5,
        },
    };
});
