import { Platform } from 'react-native';
import { rem } from 'design-system/values';
const toggle = {
    on: '#962DFF',
    off: '#6E719180',
};
const control = {
    hoverOnLight: '#6E71910D',
    hoverOnDark: '#6E71911A',
};
const borders = {
    logoStroke: '#6E71910D',
    divider: '#6E71910D',
    strongDivider: '#6E719180',
    iconStroke: '#FFFFFF',
};
const text = {
    white: '#FFFFFF',
    whiteOnColor: '#FFFFFF',
    whitePlaceholder: '#FFFFFFB3',
    primary: '#17102A',
    onPrimary: '#FFFFFF',
    secondary: '#6E7191',
    tertiary: '#9A9CB2',
    brand: '#962DFF',
    textOnBrand: '#962DFF',
    textOnBrandLight: '#962DFF',
    income: '#46BCF8',
    negative: '#FF4572',
    positive: '#16C1B7',
    feed: '#FB79DA',
    more: '#FFA27C',
    placeholder: '#9A9CB2',
    selection: '#962DFF80',
    debt: '#74768C',
    investment: '#1BD8A9',
    textOnDisabledButton: '#FFFFFFB3',
    statusPositive: '#23B8BB',
    statusNegative: '#E95C7E',
    statusInformational: '#FA8771',
    statusOffline: '#6E7191',
};
const background = {
    contrast: '#110942',
    dark: '#F5F6F9',
    light: '#FFFFFF',
    transparent: '#FFFFFF33',
    brand: '#962DFF',
    brandMedium: '#EAD3FF',
    secondary: '#9A9CB2',
    frosted: '#FFFFFF33',
    brandLight: '#F5EAFF',
    underlay: '#17102A04',
    logo: '#FFFFFF',
    black: '#000',
    fullTransparent: 'rgba(0, 0, 0, 0)',
    artwork: '#F8F9FB',
    verticalTab: '#FFFFFF',
};
const cards = {
    onDark: '#FFFFFF',
    onLight: '#F5F6F9',
    transparentOnDark: '#6E71910D',
    brand: '#962DFF',
};
const buttons = {
    primary: '#17102A',
    primaryOnBrand: '#17102A',
    primaryOnBrandDisabled: '#FFFFFF80',
    secondary: '#F5F6F9',
    brand: '#962DFF',
    brandLight: '#F5EAFF',
    brandGroup: '#F5EAFF',
    frosted: '#FFFFFF33',
    onColor: '#B56CFF',
    blackAbsolutelyOnBrand: '#000',
    negativeLight: '#FFF4F7',
    negativePrimary: '#FF4572',
    brandDisabled: '#D8BAFB',
    brandHover: '#832BDC',
    brandLightHover: '#E4D6F1',
    brandGroupHover: '#E4D6F1',
};
const elements = {
    black: '#17102A',
    white: '#FFFFFF',
    floatingWhite: '#FFFFFF',
    brandLight: '#F5EAFF',
    brandMedium: '#EAD3FF',
    primary: '#17102A',
    secondary: '#6E7191',
    secondaryFill: '#6E7191',
    secondaryLight: '#9A9CB2',
    secondaryTransparent: '#6E719180',
    secondaryTransparentFill: '#ACADBF',
    secondaryLightTransparent: '#9A9CB280',
    onLight: '#FFFFFF',
    brand: '#962DFF',
    feed: '#E270FB',
    positive: '#04DEC5',
    positiveLight: '#04DEC512',
    negative: '#FF4572',
    income: '#47BDFA',
    green: '#23B8BB',
    primaryOnModal: '#050308',
    brandOnModal: '#221336',
    statusPositive: '#04DEC5',
    statusPositiveLight: '#04DEC512',
    statusNegative: '#FF4572',
    statusNegativeLight: '#FF457212',
    statusInformational: '#FF9E69',
    statusInformationalLight: '#FF9E6917',
    statusOffline: '#6E7191',
    statusOfflineLight: '#6E71910D',
};
const status = {
    negative: '#E95C7E',
    negativeLight: '#E95C7E0F',
    positive: '#23B8BB',
    positiveLight: '#23B8BB12',
    informational: '#FF9783',
    informationalLight: '#FF978314',
    offline: '#6E7191',
    offlineLight: '#6E71910D',
};
const gradients = {
    white: {
        colors: ['#FFFFFF', '#FFFFFF'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    metallic: {
        colors: ['#FFFFFF00', '#FFFFFFFF', '#FFFFFF00'],
        locations: [0, 0.5, 1],
        useAngle: true,
        angle: 90,
    },
    ultimate: {
        colors: ['#860DFF', '#9C39FF'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    pro: {
        colors: ['#1CDCE5', '#09C7D1'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    plus: {
        colors: ['#FFCA76', '#FFC470'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    green: {
        colors: ['#04DEC5', '#04DEC5'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    darkPurple: {
        colors: ['#7C4EFF', '#6F3DFF'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    lightPurple: {
        colors: ['#A64AFE', '#CD68F1'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    darkPink: {
        colors: ['#D65EFF', '#E778F9'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    lightPink: {
        colors: ['#F56CD9', '#FF82DA'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    deepPink: {
        colors: ['#FF5191', '#FF55B1'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    apricot: {
        colors: ['#FF8584', '#FF7872'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    orange: {
        colors: ['#FFA778', '#FF9287'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    peach: {
        colors: ['#FF91A6', '#FFA6B0'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    yellow: {
        colors: ['#FFCC69', '#FFE17B'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    lime: {
        colors: ['#BCF977', '#DBF023'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    turquoise: {
        colors: ['#22EEEE', '#32E9E4'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    skyBlue: {
        colors: ['#30CEFF', '#69D2FF'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    red: {
        colors: ['#FF2775', '#FF2E77'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    headerGradientCards: {
        colors: ['#860DFF', '#9C39FF'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    brandGradient: {
        colors: ['#860DFF', '#9C39FF'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
    negativeCards: {
        colors: ['#FF2775', '#FF2E77'],
        locations: [0, 1],
        useAngle: true,
        angle: 180,
    },
    brandCards: {
        colors: ['#860DFF', '#9C39FF'],
        locations: [0, 1],
        useAngle: true,
        angle: 90,
    },
};
const categories = {
    bills: '#FF9A35',
    business: '#FF77E3',
    cash: '#FFCC00',
    charity: '#56D8FF',
    custom: '#C3C4D9',
    eatOut: '#00DEC4',
    education: '#FF9191',
    entertainment: '#B25BFF',
    family: '#69E291',
    general: '#FF79B3',
    groceries: '#29DCDF',
    personalCare: '#FF4D8D',
    holidays: '#6085FF',
    housing: '#A0E449',
    income: elements.income,
    investment: text.investment,
    shopping: '#8D64FF',
    transport: '#FF8EA3',
};
const day = {
    toggle,
    control,
    borders,
    text,
    background,
    cards,
    buttons,
    elements,
    status,
    gradients,
    categories,
    shadows: {
        chart: '#E9E5F4',
        androidShadowGradientStart: 'rgba(0,10,56, 0.05)',
        androidShadowGradientEnd: '#F5F6F900',
        tabBar: {
            shadowRadius: 24 / 2.0,
            shadowColor: 'rgb(0,10,56)',
            shadowOpacity: 0.15,
            shadowOffset: { height: 8, width: 0 },
            elevation: 10,
        },
        floatingButton: {
            ...Platform.select({
                ios: {
                    shadowColor: '#6b73a04d',
                    shadowOpacity: 1,
                    shadowOffset: { width: 0, height: 20 },
                    shadowRadius: 20,
                },
            }),
        },
        floatingSquircle: {
            ...Platform.select({
                ios: {
                    shadowColor: '#6b73a04d',
                    shadowOpacity: 1,
                    shadowOffset: { width: 0, height: 20 },
                    shadowRadius: 20,
                },
                android: {
                    elevation: 10,
                },
            }),
        },
        logoShadow: {
            ...Platform.select({
                ios: {
                    shadowColor: '#6E719180',
                    shadowRadius: 10,
                    shadowOpacity: 1,
                    shadowOffset: { width: 0, height: 10 },
                },
                android: {
                    elevation: 5,
                },
            }),
        },
        logoShadowInner: {
            ...Platform.select({
                ios: {
                    shadowColor: '#17102A',
                    shadowRadius: 2,
                    shadowOpacity: 0.5,
                    shadowOffset: { width: 0, height: 5 },
                },
            }),
        },
        logoShadowInnerLight: {
            ...Platform.select({
                ios: {
                    shadowColor: '#FFFFFF',
                    shadowRadius: 1,
                    shadowOpacity: 0.2,
                    shadowOffset: { width: 0, height: 1 },
                },
            }),
        },
        receipt: {
            ...Platform.select({
                ios: {
                    shadowColor: 'rgba(19, 48, 104, 0.15)',
                    shadowOffset: { width: rem(10), height: rem(15) },
                    shadowRadius: rem(40),
                    shadowOpacity: 1,
                },
                android: {
                    elevation: 2,
                },
            }),
        },
        fakeReceipt: {
            ...Platform.select({
                ios: {
                    shadowColor: 'rgba(19, 48, 104, 1)',
                    shadowOffset: { width: rem(0), height: rem(15) },
                    shadowRadius: rem(10),
                    shadowOpacity: 0.2,
                },
                android: {
                    elevation: 0,
                },
            }),
        },
        cardLight: {
            ...Platform.select({
                ios: {
                    shadowColor: '#13306801',
                    shadowOpacity: 1,
                    shadowOffset: { width: 3, height: 3 },
                    shadowRadius: 20,
                },
                android: {
                    elevation: 2,
                },
            }),
        },
        card: {
            ...Platform.select({
                ios: {
                    shadowColor: '#13306812',
                    shadowOpacity: 1,
                    shadowOffset: { width: 10, height: 15 },
                    shadowRadius: 40,
                },
            }),
        },
        extendedHeader: {
            ...Platform.select({
                ios: {
                    shadowColor: '#13306812',
                    shadowOpacity: 1,
                    shadowOffset: { width: 0, height: 20 },
                    shadowRadius: 10,
                },
            }),
        },
        cardMedium: {
            ...Platform.select({
                ios: {
                    shadowColor: '#133068',
                    shadowOffset: { height: 3, width: 0 },
                    shadowRadius: 40,
                    shadowOpacity: 0.14,
                },
                android: {
                    elevation: 4,
                },
            }),
        },
        cardLarge: {
            ...Platform.select({
                ios: {
                    shadowColor: '#133068',
                    shadowOffset: { height: 3, width: 0 },
                    shadowRadius: 40,
                    shadowOpacity: 0.14,
                },
                android: {
                    elevation: 10,
                },
            }),
        },
        badge: {
            ...Platform.select({
                ios: {
                    shadowColor: '#13306812',
                    shadowOpacity: 1,
                    shadowOffset: { width: 10, height: 15 },
                    shadowRadius: 40,
                },
                android: {
                    elevation: 2,
                },
            }),
        },
        headerAnimatedShadow: {
            ...Platform.select({
                ios: {
                    shadowColor: '#13306812',
                    shadowOffset: { height: 3, width: 0 },
                    shadowRadius: 40,
                    shadowOpacity: 1,
                },
                android: {
                    elevation: 0,
                },
            }),
        },
        headerAnimatedShadowWithElevation: {
            ...Platform.select({
                ios: {
                    shadowColor: '#13306812',
                    shadowOffset: { height: 3, width: 0 },
                    shadowRadius: 40,
                    shadowOpacity: 1,
                },
                android: {
                    elevation: 5,
                },
            }),
        },
        stockScreenCTA: {
            ...Platform.select({
                ios: {
                    shadowColor: '#13306812',
                    shadowOpacity: 1,
                    shadowOffset: { width: 10, height: 15 },
                    shadowRadius: 40,
                },
                android: {
                    elevation: 15,
                },
            }),
        },
        budgetingChartShadow: {
            ...Platform.select({
                ios: {
                    shadowColor: 'rgba(111, 123, 255, 0.18)',
                    shadowRadius: 8,
                    shadowOpacity: 1,
                    shadowOffset: { width: 6, height: 6 },
                },
                android: {
                    elevation: 0,
                },
            }),
        },
        recommendationCard: {
            ...Platform.select({
                ios: {
                    shadowRadius: 13 / 2.0,
                    shadowColor: 'rgb(0,10,56)',
                    shadowOpacity: 0.2,
                    shadowOffset: { width: 0, height: 3 },
                },
                android: {
                    elevation: 10,
                },
            }),
        },
        subscriptionsLogo: {
            shadowOpacity: 1,
            shadowRadius: 24,
            shadowColor: 'rgba(77, 80, 149, 0.13)',
            shadowOffset: {
                height: 16,
                width: 0,
            },
            elevation: 3,
        },
        subscriptionsLogoBolder: {
            shadowOpacity: 0.4,
            shadowColor: '#17102A',
            shadowOffset: {
                height: rem(7),
                width: 0,
            },
            shadowRadius: rem(10),
        },
        reportGiphyCard: {
            ...Platform.select({
                ios: {
                    shadowRadius: 13 / 2.0,
                    shadowColor: 'rgb(0,10,56)',
                    shadowOpacity: 0.2,
                    shadowOffset: { width: 0, height: 3 },
                },
                android: {
                    elevation: 10,
                },
            }),
        },
        reportImageCard: {
            ...Platform.select({
                ios: {
                    shadowRadius: 15.01,
                    shadowColor: 'rgb(52, 39, 133)',
                    shadowOpacity: 0.16,
                    shadowOffset: { width: -15.01, height: -15.01 },
                },
                android: {
                    elevation: 10,
                },
            }),
        },
        graphBar: {
            ...Platform.select({
                ios: {
                    shadowRadius: 34,
                    shadowColor: 'rgb(7, 28, 67)',
                    shadowOpacity: 0.28,
                    shadowOffset: { width: 9, height: 14 },
                },
                android: {
                    elevation: 10,
                },
            }),
        },
        statusIconContainer: {
            ...Platform.select({
                ios: {
                    shadowRadius: 20,
                    shadowOpacity: 0.16,
                    shadowColor: 'rgb(107, 115, 160)',
                    shadowOffset: { width: 0, height: 20 },
                },
                android: {
                    elevation: 10,
                },
            }),
        },
        squircle: {
            ...Platform.select({
                ios: {
                    shadowColor: '#133068',
                    shadowOffset: { height: 3, width: 0 },
                    shadowRadius: 40,
                    shadowOpacity: 0.07,
                },
                android: {
                    elevation: 15,
                },
            }),
        },
        webFloatingCard: {
            elevation: 2,
            shadowColor: '#133068',
            shadowOpacity: 0.01,
            shadowOffset: { width: 1, height: 1 },
            shadowRadius: 10,
        },
        rewardPreview: '#6E7191',
    },
    tabBar: {
        default: '#A3B2C5',
    },
    invest: {
        header: elements.secondary,
    },
    statusBar: {
        regular: 'dark-content',
    },
};
export default day;
